<template>
    <v-row>
        <v-col cols="12">
            <v-card elevation="0" fill class="mt-4" v-if="notificacion">
                <v-alert dense type="error" icon="mdi-web-sync">
                    <v-row align="center">
                        <v-col class="grow">
                            <span v-if="segundosresponse == -1">{{ fecha }}</span>
                            <span v-else>
                                No se han recibido datos del reloj checador desde {{ fecha }}. Por favor, revise el equipo
                                encargado de la sincronización.
                            </span>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card>
        </v-col>
        <v-col cols="4">
            <datePicker v-model="inicio" format="YYYY-MM-DD" label="Desde (Obligatorio)" clearable maxToday
                @input="getDataRC()"></datePicker>
        </v-col>
        <v-col cols="4">
            <datePicker v-model="fin" format="YYYY-MM-DD" label="Hasta (Obligatorio)" clearable maxToday
                @input=" getDataRC()"></datePicker>
        </v-col>
        <v-col cols="4">
            <v-autocomplete v-model="idEmployee" :items="itemsEmployee" item-value="idPersona"
                :item-text="nombrePersona" @input="getDataRC()" clearable
                label="Empleado (Obligatorio para Descarga de Reporte)"></v-autocomplete>
        </v-col>
        <v-col cols="12">
            <DownloadBtn text color="primary" className="my-0" @click="descargarReporte"
                label="Descargar Reporte por usuario"
                :disabled="this.inicio == null || this.fin == null || this.idEmployee == null ? true : false" />
        </v-col>
        <v-col cols="12">
            <v-data-table :headers="headers" :items="formattedData" :loading="loadingData">
                <template v-slot:body="{ items }">
                    <tbody>
                        <template v-if="items.length === 0">
                            <tr>
                                <td colspan="4" style="text-align: center;">No hay datos disponibles</td>
                            </tr>
                        </template>
                        <template v-else>
                            <template v-for="item in items">
                                <tr v-for="(info, index) in item.informacion" :key="info.fechaOrden">
                                    <template v-if="index === 0">
                                        <td :rowspan="item.rows">{{ item.persona }}</td>
                                    </template>
                                    <td>{{ info.metodo }}</td>
                                    <td><v-chip :style="{ backgroundColor: info.color }">{{ info.evento }}</v-chip></td>
                                    <td>{{ info.fecha }}</td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </template>
            </v-data-table>            
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
import moment from "moment";
import DownloadBtn from "../../DownloadBtn.vue";
export default {
    components: {
        DownloadBtn,
    },
    data: () => ({
        historial_total: 0,
        historial_items_per_page: 10,
        historial_actual_page: 1,
        formattedData: [],
        headers: [
            { text: "Empleado", value: "persona" },
            { text: "Método de Autentificación", value: "metodo" },
            { text: "Evento", value: "evento" },
            { text: "Fecha", value: "fecha" },
        ],
        load: false,
        inicio: null,
        fin: null,
        itemsEmployee: [],
        idEmployee: null,
        btnDounwload: false,
        fecha: null,
        mensaje: null,
        notificacion: true,
        segundos: 300,
        segundosresponse: null,
        loadingData: false
    }),
    mounted() {
        this.initialize();
    },
    created() {
        this.ultimaSincronizacion()
    },
    computed: {
        historial_fetch_url() {
            return (
                `/Reportes/GetDatosRelojChecador?paginar=false` +
                this.historial_filtros_url
            );
        },
        historial_filtros_url() {
            var filtro = "";
            if (this.idEmployee != null) filtro += `&IdPersona=${this.idEmployee}`;
            if (this.inicio != null) filtro += `&Inicio=${this.inicio}`;
            if (this.fin != null) filtro += `&Fin=${this.fin}`;

            return filtro;
        },
        ...mapState(["token"])
    },
    methods: {
        nombrePersona: (persona) => //Agregado para funcionamiento del filtro empleados
            persona.nombre + " " + persona.paterno + " " + persona.materno,
        initialize() {
            this.getOperadores();
        },
        dateSet(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        getOperadores() {
            axios
                .get("/Personas/OperadoresBasico")
                .then((response) => {
                    this.itemsEmployee = response.data;
                    //console.log(this.itemsEmployee);
                })
                .catch((error) => {
                    Error.log(error);
                });
        },
        contenidofiltros() {
            return this.inicio != null && this.fin != null;
        },
        descargarReporte() {
            var datetime = moment().format("YYYYMMDDHHmmss");

            let url = `/Reportes/XlsReporteUsuarioChecador?access_token=${this.token}`;
            if (this.inicio != null) url += `&fechaInicio=${this.inicio}`;
            if (this.fin != null) url += `&fechaFin=${this.fin}`;
            if (this.idEmployee != null) url += `&IdPersona=${this.idEmployee}`;

            this.$utils.axios.downloadFile(
                url,
                "GET",
                "reporte-relojCheador-" + datetime + ".xlsx",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
        },
        ultimaSincronizacion() {
            axios
                .get("/RelojChecador/UltimaSincronizacion")
                .then(response => {
                    this.segundos = 300;
                    this.fecha = response.data.fecha;
                    this.segundosresponse = response.data.minutos;

                    if (response.data.minutos > 15 || response.data.minutos == -1)
                        this.notificacion = true;
                    else
                        this.notificacion = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getDataRC() {
            if (this.inicio == null || this.fin == null) {
                this.formattedData = []
                return;
            }

            this.loadingData = true;
            axios
                .get(this.historial_fetch_url)
                .then(response => {
                    this.formattedData = response.data;
                    this.loadingData = false;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
};
</script>