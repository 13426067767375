import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.notificacion)?_c(VCard,{staticClass:"mt-4",attrs:{"elevation":"0","fill":""}},[_c(VAlert,{attrs:{"dense":"","type":"error","icon":"mdi-web-sync"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[(_vm.segundosresponse == -1)?_c('span',[_vm._v(_vm._s(_vm.fecha))]):_c('span',[_vm._v(" No se han recibido datos del reloj checador desde "+_vm._s(_vm.fecha)+". Por favor, revise el equipo encargado de la sincronización. ")])])],1)],1)],1):_vm._e()],1),_c(VCol,{attrs:{"cols":"4"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","label":"Desde (Obligatorio)","clearable":"","maxToday":""},on:{"input":function($event){return _vm.getDataRC()}},model:{value:(_vm.inicio),callback:function ($$v) {_vm.inicio=$$v},expression:"inicio"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","label":"Hasta (Obligatorio)","clearable":"","maxToday":""},on:{"input":function($event){return _vm.getDataRC()}},model:{value:(_vm.fin),callback:function ($$v) {_vm.fin=$$v},expression:"fin"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.itemsEmployee,"item-value":"idPersona","item-text":_vm.nombrePersona,"clearable":"","label":"Empleado (Obligatorio para Descarga de Reporte)"},on:{"input":function($event){return _vm.getDataRC()}},model:{value:(_vm.idEmployee),callback:function ($$v) {_vm.idEmployee=$$v},expression:"idEmployee"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('DownloadBtn',{attrs:{"text":"","color":"primary","className":"my-0","label":"Descargar Reporte por usuario","disabled":this.inicio == null || this.fin == null || this.idEmployee == null ? true : false},on:{"click":_vm.descargarReporte}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.formattedData,"loading":_vm.loadingData},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[(items.length === 0)?[_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_vm._v("No hay datos disponibles")])])]:[_vm._l((items),function(item){return _vm._l((item.informacion),function(info,index){return _c('tr',{key:info.fechaOrden},[(index === 0)?[_c('td',{attrs:{"rowspan":item.rows}},[_vm._v(_vm._s(item.persona))])]:_vm._e(),_c('td',[_vm._v(_vm._s(info.metodo))]),_c('td',[_c(VChip,{style:({ backgroundColor: info.color })},[_vm._v(_vm._s(info.evento))])],1),_c('td',[_vm._v(_vm._s(info.fecha))])],2)})})]],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }