<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogDeleteBono" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar este Bono?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="hide_dialogDelete()" class="ml-auto">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="deleteBono()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-data-table :headers="headers" :search="search" :items="bonos" :loading="loadingTable">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="700" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" @click="show_dialog()">Nuevo Bono</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ optionDialog == 1 ? "Crear Bono" : "Editar Bono" }}</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="bonoData.codigo" label="Código" v-bind="propsText" />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="bonoData.nombre" label="Nombre" v-bind="propsText" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete v-model="bonoData.idTurno" label="Turno [Opcional]" :items="turnos"
                          item-text="nombre" item-value="idTurno" v-bind="propsAutocompleteNull" />
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="bonoData.idArea" label="Área [Opcional]" :items="areas"
                          item-text="nombre" item-value="idArea" v-bind="propsAutocompleteNull" />
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="bonoData.idCargo" label="Puesto" :items="puestos" item-text="nombre"
                          item-value="idPuesto" v-bind="propsAutocomplete" />
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="bonoData.idMaquina" label="Máquina [Opcional]" :items="maquinas"
                          :item-text="nombreMaquina" item-value="idMaquina" v-bind="propsAutocompleteNull" />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="bonoData.numHabilidades" label="Número de habilidades"
                          v-bind="propsEnteros" @wheel.prevent />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="bonoData.total" label="Monto del bono" v-bind="propsDecimales1"
                          @wheel.prevent prefix="$" />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="bonoData.dias" label="Periodo del bono en días" v-bind="propsEnteros1"
                          @wheel.prevent suffix="Días" />
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="conceptosSeleccionados" label="Conceptos aplicables"
                          :items="conceptosBonos" item-text="descripcion" item-value="idConceptoBono" multiple />
                      </v-col>
                    </v-row>

                    <v-simple-table height="auto" v-if="bonosfiltradosSelec.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              Concepto
                            </th>
                            <th class="text-center">
                              Ponderación
                            </th>
                            <th class="text-center">
                              Objetivo
                            </th>
                            <th class="text-center">
                              Tolerancia
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="concepto in bonosfiltradosSelec" :key="concepto.idConceptoBono">
                            <td>{{ concepto.descripcion ? concepto.descripcion : "" }}</td>
                            <td><v-text-field v-model="concepto.ponderacion" label="Ponderación" suffix="/100"
                                v-bind="propsEnteros"  @wheel.prevent/></td>
                            <td><v-text-field v-model="concepto.objetivo" label="Objetivo"
                                :suffix="concepto.unidadObjetivo" v-bind="propsEnteros"  @wheel.prevent/></td>
                            <td><v-text-field v-model="concepto.tolerancia" label="Tolerancia"
                                :suffix="concepto.unidadTolerancia" v-bind="propsEnteros"  @wheel.prevent/></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-form>
                <ul>
                  <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-btn :loading="loadingDialog" color="blue darken-1" text @click="hide_dialogBonos()"
                  class="ml-auto">Cancelar</v-btn>
                <v-btn :loading="loadingDialog" color="red darken-1" text @click="validate()">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" v-bind="propsSearch" single-line hide-details />
        </v-toolbar>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialog(item)">mdi-pen</v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-1" @click="show_dialogDelete(item)">mdi-trash-can</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:item.total="{ item }">
        ${{ item.total }}
      </template>
    </v-data-table>
    <v-snackbar v-model="alerta" top color="error">
      Existe una Habilidad Activa con el mismo nombre
      <v-btn text @click="alerta = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      btnClass: 'my-button-class',

      propsSearch: {
        clearable: true,
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      propsSearchAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
      },

      propsText: {
        outlined: false,
        dense: false,
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            }
            return true;
          },
        ],
      },
      propsTextArea: {
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsAutocompleteNull: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle'
      },
      propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsAutocompleteMultiple: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
        required: true,
        multiple: true,
        chips: true,
        rules: [
          (v) => !!v || "Dato Requerido"
        ],
      },
      propsSelect: {
        multiple: false,
        chips: false,
        dense: false,
        clearable: true,
        'clear-icon': "mdi-close-circle",
      },

      propsDecimales: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsDecimales1: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (Number(v) < 1) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsEnteros: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (!Number.isInteger(Number(v))) {
              return "Ingrese un número entero";
            } else if (Number(v) < 0) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },
      propsEnteros1: {
        outlined: false,
        dense: false,
        type: "number",
        inputmode: "numeric",
        required: true,
        rules: [
          (v) => {
            if (v === null || v === "") {
              return "Dato requerido";
            } else if (!Number.isFinite(Number(v))) {
              return "Ingrese solo números";
            } else if (!Number.isInteger(Number(v))) {
              return "Ingrese un número entero";
            } else if (Number(v) < 1) {
              return "Dato no valido";
            }
            return true;
          },
        ],
      },

      dialogEleccion: false,
      dialog: false,
      dialogDeleteBono: false,
      optionDialog: 1,

      search: "",

      bonoTipo: 1,

      bonoData: {
        codigo: null,
        nombre: null,
        idTipo: null,
        idTurno: null,
        idArea: null,
        idCargo: null,
        idMaquina: null,
        numHabilidades: null,
        total: 0,
        dias: null,

        bonoConceptos: []
      },
      bonoDataDef: {
        codigo: null,
        nombre: null,
        idTipo: null,
        idTurno: null,
        idArea: null,
        idCargo: null,
        idMaquina: null,
        numHabilidades: null,
        total: 0,
        dias: null,

        bonoConceptos: []
      },
      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          sortable: false
        }, {
          text: "Nombre",
          value: "nombre",
          align: "center",
          sortable: false
        }, {
          text: "Puesto",
          value: "nombreCargo",
          align: "center",
          sortable: false
        }, {
          text: "Monto",
          value: "total",
          align: "center",
          sortable: false
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        }
      ],

      conceptosBonos: [],
      bonos: [],
      turnos: [],
      areas: [],
      puestos: [],
      maquinas: [],
      conceptosSeleccionados: [],

      errores: [],

      alerta: false,
      loadingTable: false,
      loadingDialog: false,
    }
  },
  methods: {
    nombreMaquina(item) {
      return `${item.marca} - [${item.modelo}]`
    },
    initialize() {
      this.getTurnos();
      this.getAreas();
      this.getDepartamentos();
      this.getPuestos();
      this.getMaquinas();
      this.getBonos();
      this.getConceptosBonos()
    },

    validate() {
      this.errores = [];
      let totalPonderacion = 0;
      const ponderaciones = this.bonosfiltradosSelec.map(item => parseInt(item.ponderacion));
      for (let i of ponderaciones) totalPonderacion += i;

      if (!this.$refs.form.validate()) {
        this.agregarError(500, "Faltan datos obligatorios");
        return;
      }
      if (this.bonosfiltradosSelec.length <= 0) {
        this.agregarError(500, "Se debe aplicar al menos un concepto");
        return;
      }
      if (totalPonderacion != 100) {
        this.agregarError(500, "La ponderación total debe ser igual a 100");
        return;
      }
      //this.optionDialog == 'Crear' ? this.postBonos() : this.putBonos();
      this.optionDialog == 1 ? this.postBonos() : this.putBonos();
    },

    getTurnos() {
      axios
        .get("Turnos")
        .then(response => {
          this.turnos = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getAreas() {
      axios
        .get("Areas")
        .then(response => {
          this.areas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getDepartamentos() {
      axios
        .get("Departamento")
        .then(response => {
          this.departamentos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPuestos() {
      axios
        .get("Puestos")
        .then(response => {
          this.puestos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getMaquinas() {
      axios
        .get("Maquinas")
        .then(response => {
          this.maquinas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getConceptosBonos() {
      axios
        .get("/Bonos/ListarConceptoBonos")
        .then(response => {
          this.conceptosBonos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getBonos() {
      this.loadingTable = true;
      axios
        .get("Bonos/Listar")
        .then(response => {
          this.bonos = response.data
        })
        .catch(error => { console.log(error.response) })
        .finally(() => {
          this.loadingTable = false;
        })
    },
    postBonos() {
      this.loadingDialog = true;
      this.bonoData.idTipo = this.bonoData.idCargo;
      axios
        .post("/Bonos/Crear", this.bonoData)
        .then(response => {
          this.getBonos();
          this.hide_dialogBonos();
        })
        .catch(error => {
          console.log(error.response);
          if (error.response) {
            if (error.response.status == 409) {
              this.alerta = true;
              this.errores.push("Existe un Bono Activo con el Mismo Nombre");
            }
          }
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    putBonos() {
      this.loadingDialog = true;
      axios
        .put(`/Bonos/Actualizar/${this.bonoData.idBono}`, this.bonoData)
        .then(response => {
          this.getBonos();
          this.hide_dialogBonos();
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            if (error.response.status == 409) {
              this.alerta = true;
              this.errores.push("Existe un Bono Activo con el Mismo Nombre");
            }
          }
        })
        .finally(() => {
          this.loadingDialog = false;
        })
    },
    deleteBono() {
      if (!this.bonoData || !this.bonoData.idBono) return

      this.dialogDeleteBono = true;
      axios
        .put(`/Bonos/Desactivar/${this.bonoData.idBono}`)
        .then(response => {
          this.getBonos();
          this.hide_dialogDelete();
        })
        .catch((error) => { console.log(error.response) })
        .finally(() => {
          this.dialogDeleteBono = false;
        })
    },

    mostrar_TiposUsuarios() {
      this.dialogEleccion = true;
    },
    /*async asignarConceptosBonos() {
      this.bonoTipo = parseInt(this.bonoTipo);
      switch (this.bonoTipo) {
        case 1:
          this.bonoData.bonoConceptos = JSON.parse(JSON.stringify(this.conceptosBonos));
          break;
        case 2:
          this.bonoData.bonoConceptos = JSON.parse(JSON.stringify(this.conceptosBonos));
          break;
      }
    },*/
    /*async selectTipoUsuario() {
      this.bonoData.bonoConceptos = JSON.parse(JSON.stringify({}));
      this.dialogEleccion = false;

      //await this.asignarConceptosBonos();
      this.bonoData.idTipo = this.bonoTipo;
      this.show_dialog();
    },*/
    show_dialog(item) {
      this.dialog = true;
      //this.optionDialog = item ? "Editar" : "Crear";
      this.optionDialog = item ? 2 : 1;

      if (item) {
        this.bonoData = JSON.parse(JSON.stringify(item));
        this.conceptosSeleccionados = item.conceptosBonos;

        /*this.conceptosBonos.forEach(concepto => {
          let idx = this.bonoData.bonoConceptos.findIndex(consLocal => consLocal.idConceptoBono === concepto.idConceptoBono);
          if (idx !== -1) {
            this.bonoData.bonoConceptos[idx] = { ...this.bonoData.bonoConceptos[idx], concepto }
          } else {
            this.bonoData.bonoConceptos.push(concepto)
          }
        })*/

      } else {
        //this.bonoData.bonoConceptos = JSON.parse(JSON.stringify(this.conceptosBonos));
      }
    },
    hide_dialogBonos() {
      this.$refs.form?.reset();
      this.dialog = false;
      this.conceptosSeleccionados = [];
      //Object.assign(this.bonoData, this.bonoDataDef);
      this.vaciarErrores();
    },

    show_dialogDelete(item) {
      if (item) {
        Object.assign(this.bonoData, item);
      }
      console.log("Eliminar")
      this.dialogDeleteBono = true;
    },
    hide_dialogDelete() {
      this.dialogDeleteBono = false;
      this.bonoData = JSON.parse(JSON.stringify(this.bonoDataDef));
    },

    agregarError(error = 500, descrip) {
      if (descrip) {
        this.errores.push(descrip)
      } else {
        switch (error) {
          case 500:
            this.errores.push("Error");
            break
          case 401:
            this.errores.push("Hubo un problema con los datos proporcionados.");
            break
          case 409:
            this.errores.push("El recurso que intentas crear ya existe.")
            break;
          default:
            this.errores.push("Error.")
            break
        }
      }
      let data = new Set(this.errores);
      this.errores = [...data];
    },
    vaciarErrores() {
      this.errores = JSON.parse(JSON.stringify({}))
    }

  },
  computed: {
    bonosfiltradosSelec() {
      let conceptos = []

      const idsConceptos = this.conceptosBonos.map(item => item.idConceptoBono)
      const idsSeleccionados = this.conceptosSeleccionados

      const idsNoSeleccionados = idsConceptos.filter(id => !idsSeleccionados.includes(id))

      this.conceptosSeleccionados.forEach(id => {
        const conceptoExistente = this.bonoData.bonoConceptos.find(item => item.idConceptoBono == id)

        if (conceptoExistente != null && conceptoExistente != undefined) {
          conceptos.push(conceptoExistente)
          return
        } else {
          const conceptoNuevo = this.conceptosBonos.find(item => item.idConceptoBono == id)
          conceptos.push(conceptoNuevo)
        }        
      })

      idsNoSeleccionados.forEach(id => {
        //let data =this.bonoData.bonoConceptos.find(item => item.idConceptoBono == id)
        let data = this.conceptosBonos.find(item => item.idConceptoBono == id)

        data.tolerancia = null
        data.objetivo = null
        data.ponderacion = null
      })
      this.bonoData.bonoConceptos = conceptos
      return conceptos
    }
  },
  mounted() {
    this.initialize();
  },
}
</script>

<style scoped>
.my-button-class {
  color: rgb(0, 123, 255) !important;
}

.v-btn--active.my-button-class {
  background-color: rgb(0, 123, 255) !important;
  color: white !important;
}
</style>