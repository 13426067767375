import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Agregar Nuevo Usuario ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.notificacionesDatos,"loading":_vm.saving},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VAutocomplete,{attrs:{"label":"Nombre","items":_vm.usuariosNoAsignados,"item-value":"idUsuario","item-text":_vm.nombreUsuarios,"disabled":_vm.saving,"loading":_vm.saving},model:{value:(_vm.editedItem.idUsuario),callback:function ($$v) {_vm.$set(_vm.editedItem, "idUsuario", $$v)},expression:"editedItem.idUsuario"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"red darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("¿Está seguro que desea eliminar esta regla?")]),_c(VCardText,[_vm._v("Esta acción no se puede revertir y será permanente.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Aceptar")]),_c(VSpacer)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }