<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-if="whatsapp == true"
				@click="whatsappClick"
			>
				Asociar mi Cuenta con Whatsapp
			</v-tab>

			<v-tab
				v-if="linkTelegram != null"
				@click="telegramClick"
			>
				Asociar mi Cuenta con Telegram
			</v-tab>

			<v-tab
				@click="passwordClick"
			>
				Cambiar Contraseña
			</v-tab>
			<v-tab
				@click="emailClick"
			>
				Cambiar Email
			</v-tab>

			<v-tabs-items v-model="tab" touchless>

				<v-tab-item
					v-if="whatsapp == true"
					eager
				>
					<AsociarWhatsapp ref="whatsapp" />
				</v-tab-item>

				<v-tab-item
					v-if="linkTelegram != null"
					eager
				>
					<AsociarTelegram ref="telegram" />
				</v-tab-item>

				<v-tab-item
					eager
				>
					<CambiarPassword ref="password" />
				</v-tab-item>
				<v-tab-item
					eager
				>
					<CambiarEmail ref="email" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CambiarEmail from './Usuario/CambiarEmail.vue';
import CambiarPassword from './Usuario/CambiarPassword.vue';
import AsociarTelegram from './Usuario/AsociarTelegram.vue';
import AsociarWhatsapp from './Usuario/AsociarWhatsapp.vue';
import { mapState } from "vuex"


export default {
	components: {
		AsociarWhatsapp,
		AsociarTelegram,
		CambiarPassword,
		CambiarEmail
	},
	computed:{
		...mapState(["linkTelegram","whatsapp"])
	},
	data: () => ({
		tab: 0,
	}),
	mounted() {
        this.initialize();
    },
	methods: {
		// cambio(event) {
		// 	if (this.tab == 0) this.$refs.password.cambio(event);
		// 	if (this.tab == 1) this.$refs.email.cambio(event);
		// },
		initialize() {
			this.$refs.whatsapp.reset();
		},
		whatsappClick() {
			if(typeof this.$refs.whatsapp === 'undefined') return;
			this.$refs.whatsapp.reset();
		},
		telegramClick() {
			if(typeof this.$refs.telegram === 'undefined') return;
			this.$refs.telegram.reset();
		},
		passwordClick() {
			if(typeof this.$refs.password === 'undefined') return;
			this.$refs.password.reset();
		},
		emailClick() {
			if(typeof this.$refs.email === 'undefined') return;
			this.$refs.email.reset();
		},
	},
};
</script>