<template>
  <v-col cols="12" v-if="permisoVista('comprobaciones', 'r')">
    <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
      <v-card>
        <v-card-title
        >¿Está seguro que desea eliminar esta <br/>Lista de Comprobación?
        </v-card-title>
        <v-card-text
        >Esta acción no se puede revertir y será permanente.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="
              eliminar = false;
              delItem = '';
            "
          >Cancelar
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="
              eliminar = false;
              deleteItemDB();
            "
          >Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="80%" :key="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col :cols="nombreEmpresa === 'IMPSA' ? 6 : 4">
                <v-text-field
                    v-model="editedItem.nombre"
                    label="Nombre"
                    :error-messages="errorNombre"
                    @focus="errorNombre = ''"
                    :messages="action === 3 ? 'Ingresa un nombre diferente' : ''"
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-if="nombreEmpresa !== 'IMPSA'">
                <v-text-field
                    v-model="editedItem.codigo"
                    label="Código"
                    :error-messages="errorCodigo"
                    @focus="errorCodigo = ''"
                    :messages="action === 3 ? 'Ingresa un código diferente' : ''"
                ></v-text-field>
              </v-col>
              <v-col
                  :cols="
                  editedItem.tipoLista === 0
                    ? 2
                    : this.nombreEmpresa === 'IMPSA'
                    ? 6
                    : 4
                "
              >
                <v-select
                    v-model="editedItem.tipoLista"
                    label="Tipo de Lista"
                    :items="tiposListaComprobacion"
                    :error-messages="errorTipoLista"
                    @focus="errorTipoLista = ''"
                    @change="onChangeTipoLista()"
                ></v-select>
              </v-col>
              <v-col v-if="editedItem.tipoLista === 0" cols="2">
                <v-text-field
                    v-model="editedItem.tiempoRepeticion"
                    label="Repetir Cada"
                    suffix="Minutos"
                    type="number"
                    hide-spin-buttons
                    :error-messages="errorTiempoRepeticion"
                    @focus="errorTiempoRepeticion = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                    v-model="editedItem.areas"
                    :items="areas"
                    item-text="nombre"
                    item-value="idArea"
                    label="Área(s) a comprobar"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    :error-messages="errorAreas"
                    @focus="errorAreas = ''"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                    v-model="editedItem.articulos"
                    :items="articulos"
                    item-text="nombre"
                    item-value="idArticulo"
                    label="Artículo(s) a comprobar"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    :error-messages="errorArticulos"
                    @focus="errorArticulos = ''"
                />
              </v-col>
              <v-col cols="3"> <!--Campo para imagern general en comprobación calidad-->
                <v-file-input
                  v-model="editedItem.imagenGeneral" 
                  accept="image/*"
                  :label="editedItem.imagenGeneral != null ? 'Imagen cargada' : 'Subir imagen (opcional)'"
                  clearable
                  :hide-details="editedItem.imagenGeneral != null"
                  @change="subirImagenGeneral($event)"
                  @click:clear="clearImagenes"
                ></v-file-input>
                <v-btn
                  v-if="editedItem.imagenGeneral != null"
                  block
                  color="primary"
                  text
                  class="my-0"
                  :loading="loadingPreview"
                  :disabled="loadingPreview"
                  @click="showImageGeneral(imagenGeneral)"
                >
                  Vista Previa
                  <v-icon right>mdi-eye</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels
                    v-model="panels"
                    accordion
                    multiple
                    readonly
                >
                  <draggable
                      :list="editedItem.comprobaciones"
                      group="secciones"
                      handle=".seccion-movible"
                      style="width: 100%"
                      ghost-class="ghost-element"
                  >
                    <div v-for="(seccion, index) in editedItem.comprobaciones" :key="`seccion-${index}`">
                      <v-expansion-panel :key="`expansion-seccion-${index}`">
                        <v-expansion-panel-header
                            class="pa-0 px-3 grey lighten-3"
                            hide-actions
                            style="cursor: default"
                        >
                          <div
                              class="d-flex align-center justify-space-between"
                          >
                            <div style="width: 30px;">
                              <v-icon
                                  @click.stop.prevent=""
                                  class="seccion-movible"
                              >mdi-menu
                              </v-icon>
                            </div>
                            <v-text-field
                                v-model="seccion.nombreSeccion"
                                label="Nombre sección"
                                style="max-width: 300px"
                                :error-messages="seccion['errorNombreSeccion']"
                                @focus="$set(seccion, 'errorNombreSeccion', null)"
                            ></v-text-field>
                            <div class="pa-0 d-flex align-center justify-end">
                              <v-btn
                                  color="primary"
                                  class="my-0"
                                  text
                                  @click.stop.prevent="agregarItem(seccion)"
                              >
                                <v-icon left>mdi-playlist-plus</v-icon>
                                Agregar<br/>comprobación
                              </v-btn>
                              <v-tooltip
                                  bottom
                                  color="black"
                                  class="white--text"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                      v-on="on"
                                      small
                                      class="mr-2"
                                      @click="deleteSeccion(seccion)"
                                  >
                                    delete
                                  </v-icon>
                                </template>
                                <span class="white--text"
                                >Eliminar sección</span
                                >
                              </v-tooltip>
                            </div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            class="custom-padding-expansion-content"
                        >
                          <v-data-table
                              :items="seccion.preguntas"
                              :headers="headersComprobaciones"
                              no-data-text="No hay comprobaciones disponibles"
                              hide-default-footer
                              item-key="id"
                              :items-per-page="-1"
                              class="no-stripped-table"
                          >
                            <template v-slot:body="props">
                              <draggable
                                  :key="`comp-${index}`"
                                  :list="seccion.preguntas"
                                  group="comprobaciones"
                                  tag="tbody"
                                  ghost-class="ghost-element"
                                  handle=".item-movible"
                              >
                                <DataTableRowHandler
                                    v-for="(item, index) in props.items"
                                    :key="index"
                                    :item="item"
                                    :headers="headersComprobaciones"
                                    itemClass="table-font"
                                >
                                  <template v-slot:[`item.handleIcon`]>
                                    <v-icon class="item-movible"
                                    >mdi-menu
                                    </v-icon>
                                  </template>
                                  <template
                                      v-slot:[`item.comprobacion`]="{ item }"
                                  >
                                    <v-textarea
                                        v-model="item.comprobacion"
                                        auto-grow
                                        rows="1"
                                        row-height="5"
                                        :error-messages="item.errorNombre"
                                        @focus="$set(item, 'errorNombre', null)"
                                    ></v-textarea>
                                  </template>
                                  <template
                                      v-slot:[`item.tipoComprobacion`]="{ item }"
                                  >
                                    <v-col class="pa-0" cols="12">
                                      <v-row>
                                        <v-col
                                            cols="12"
                                            :md="
                                            item.tipoComprobacion === 2 ? 6 : 12
                                          "
                                        >
                                          <v-select
                                              v-model="item.tipoComprobacion"
                                              :items="tiposComprobaciones"
                                              item-text="name"
                                              item-value="value"
                                              :error-messages="item.errorTipo"
                                              @focus="
                                              $set(item, 'errorTipo', null)
                                            "
                                          ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            v-if="item.tipoComprobacion === 2"
                                            md="6"
                                        >
                                          <v-select
                                              v-model="item.idUnidad"
                                              :items="unidades"
                                              item-text="nombre"
                                              item-value="idUnidad"
                                              label="Unidad (Opcional)"
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col
                                        class="pa-0"
                                        cols="12"
                                        v-if="item.tipoComprobacion == 2"
                                    >
                                      <div class="d-flex">
                                        <v-col
                                            cols="3"
                                            class="
																						px-1
																						d-flex
																						justify-center
																					"
                                        >
                                          <v-text-field
                                              v-model="item.min"
                                              class="mt-0 pt-0"
                                              label="Intervalo min."
                                              type="number"
                                              style="width: 60px"
                                              :step="step"
                                              hide-details
                                              :error="item.errorMin"
                                              @focus="
                                              $set(item, 'errorMin', false)
                                            "
                                              @input="cambioMin($event, item)"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            class="
																						px-1
																						pt-6
																						pb-0
																						d-flex
																						justify-center
																					"
                                        >
                                          <v-range-slider
                                              v-model="item.range"
                                              :max="item.max"
                                              :min="item.min"
                                              hide-details
                                              class="
																							pt-2
																							align-center
																						"
                                              :step="step"
                                              thumb-label="always"
                                              thumb-size="40"
                                              :key="sliderKey"
                                          ></v-range-slider>
                                        </v-col>
                                        <v-col
                                            cols="3"
                                            class="
																						px-1
																						d-flex
																						justify-center
																					"
                                        >
                                          <v-text-field
                                              v-model="item.max"
                                              class="mt-0 pt-0"
                                              label="Intervalo max."
                                              type="number"
                                              style="width: 60px"
                                              :step="step"
                                              hide-details
                                              :error="item.errorMax"
                                              @focus="
                                              $set(item, 'errorMax', false)
                                            "
                                              @input="cambioMax($event, item)"
                                          ></v-text-field>
                                        </v-col>
                                      </div>
                                    </v-col>
                                    <v-col
                                        v-if="item.tipoComprobacion == 2"
                                        cols="12"
                                        class="d-flex justify-space-between pa-0"
                                    >
                                      <v-col
                                          cols="3"
                                          class="
																					px-1
																					pt-0
																				"
                                      >
                                        <v-text-field
                                            :value="item.range[0]"
                                            class="mt-0 pt-0"
                                            dense
                                            :step="step"
                                            type="number"
                                            label="Rango aceptable min."
                                            hide-details
                                            @input="cambioRango($event, item, 0)"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="3"
                                          class="
																					px-1
																					pt-0
																				"
                                      >
                                        <v-text-field
                                            :value="item.range[1]"
                                            class="mt-0 pt-0"
                                            dense
                                            :step="step"
                                            type="number"
                                            label="Rango aceptable max."
                                            hide-details
                                            @input="cambioRango($event, item, 1)"
                                        ></v-text-field>
                                      </v-col>
                                    </v-col>
                                  </template>
                                  <template v-slot:[`item.imagen`]="{ item }">
                                    <v-file-input
                                        v-model="item.imagenBlob"
                                        :key="keyImagen"
                                        accept="image/*"
                                        :label="
                                        item.imagen != null
                                          ? 'Imagen cargada'
                                          : 'Subir imagen (opcional)'
                                      "
                                        clearable
                                        :loading="!!item.loading"
                                        :disabled="!!item.loading"
                                        :hide-details="item.imagen != null"
                                        @change="subirImagen($event, item)"
                                        @click:clear="clearImagenes(item)"
                                    ></v-file-input>
                                    <v-btn
                                        v-if="item.imagen != null"
                                        block
                                        color="primary"
                                        text
                                        class="my-0"
                                        :loading="loadingPreview"
                                        :disabled="loadingPreview"
                                        @click="showImage(item)"
                                    >
                                      Vista Previa
                                      <v-icon right>mdi-eye</v-icon>
                                    </v-btn>
                                  </template>
                                  <template v-slot:[`item.action`]="{ item }">
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        class="white--text"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            small
                                            class="mr-2"
                                            @click="borrarComp(seccion, item)"
                                        >
                                          delete
                                        </v-icon>
                                      </template>
                                      <span class="white--text"
                                      >Eliminar fila</span
                                      >
                                    </v-tooltip>
                                  </template>
                                </DataTableRowHandler>
                              </draggable>
                              <tr
                                  v-if="seccion.preguntas.length === 0"
                                  class="grey lighten-4"
                                  style="height: 48px"
                              >
                                <td
                                    :colspan="headersComprobaciones.length"
                                    class="text-center text--disabled"
                                    style="font-size: 14px !important"
                                >
                                  No hay comprobaciones disponibles
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </draggable>
                </v-expansion-panels>
              </v-col>

              <v-col cols="4" class="py-0">
                <v-btn
                    color="primary"
                    class="my-0"
                    text
                    @click="agregarSeccion()"
                >
                  <v-icon left>mdi-bookmark-plus-outline</v-icon>
                  Agregar sección
                </v-btn>
              </v-col>
            </v-row>
            <ul>
              <li class="red--text" v-for="ex in errores" :key="ex">
                {{ ex }}
              </li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Cancelar</v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="save()"
              :disabled="saving"
              :loading="saving"
          >
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                  indeterminate
                  :width="2"
                  :size="24"
                  color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogShowImage"
        max-width="50%"
        @click:outside="closeShowImage()"
    >
      <v-card>
        <v-card-title class="py-1 d-flex justify-end">
          <v-btn color="primary" large text @click="closeShowImage()">
            Cerrar
            <v-icon right>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12" sm="12" class="text-center">
            <img :src="currentImage" alt="Imagen" style="max-width: 100%;"/>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="d-flex justify-space-between align-center">
      <v-col cols="12" class="mt-8">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="4" class="mt-0 pt-0">
        <v-btn
            v-if="permisoVista('comprobaciones', 'c')"
            color="primary"
            @click="showDialog()"
        >
          Nueva Lista de Comprobación
        </v-btn>
      </v-col>

      <v-col v-if="!loading" cols="6" class="mt-0 pt-0">
        <v-toolbar flat color="white">
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar por nombre de lista"
              @keyup="keyboardEventSearch"
              @click:clear="clearSearchAction"
              @click:append="listas_update_page_action(1)"
              :clearable="true"
              :disabled="loading"
              single-line
              hide-details
          />
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pt-0 pb-5">
        <v-autocomplete
            v-model="filtros.idArea"
            :items="areas"
            item-text="nombre"
            item-value="idArea"
            label="Área"
            clearable
            hide-details
            multiple
            @change="listas_update_page_action(1)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" class="pt-0 pb-5">
        <v-autocomplete
            v-model="filtros.idArticulo"
            :items="articulos"
            item-text="nombre"
            item-value="idArticulo"
            label="Artículo"
            clearable
            hide-details
            multiple
            @change="listas_update_page_action(1)"
        />
      </v-col>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="listas"
        :search="search"
        :loading="loading"
        loading-text="Cargando... Espere, Por Favor."
        :server-items-length="listas_total"
        :items-per-page="listas_items_per_page"
        :page="listas_actual_page"
        @update:page="listas_update_page_action"
        @update:items-per-page="(ipp) => (listas_items_per_page = ipp)"
        :footer-props="{
        disableItemsPerPage: true,
        disablePagination: this.loading,
      }"
        :disable-sort="true"
        :disable-pagination="loading"
        hide-default-footer
    >
      <template v-slot:[`item.tipoLista`]="{ item }">
        {{ obtenerTipoLista(item.tipoLista) }}
      </template>
      <template v-slot:[`item.articulos`]="{ item }">
        <div v-if="item.articulos == null">
          <p class="mb-0">N/A</p>
        </div>
        <div v-if="item.articulos.length === 0">
          <p class="mb-0">Cualquier Artículo</p>
        </div>
        <div v-if="item.articulos !== null && item.articulos.length > 0">
          <ul class="py-3">
            <li
                v-for="(articulo, idx) in item.articulos"
                :key="`articulo-${idx}`"
                class="pb-1"
                style="font-size: 14px !important"
            >
              {{ articulo }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.areas`]="{ item }">
        <div v-if="item.areas == null">
          <p class="mb-0">N/A</p>
        </div>
        <div v-if="item.areas.length === 0">
          <p class="mb-0">Cualquier Área</p>
        </div>
        <div v-if="item.areas != null && item.areas.length > 0">
          <ul class="py-3">
            <li
                v-for="(area, idx) in item.areas"
                :key="`area-${idx}`"
                class="pb-1"
                style="font-size: 14px !important"
            >
              {{ area }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip
            v-if="permisoVista('comprobaciones', 'u')"
            bottom
            color="black"
            class="white--text"
        >
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                small
                class="mr-2"
                @click="getComprobacionesByListaId(item)"
            >
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip
            v-if="permisoVista('comprobaciones', 'u')"
            bottom
            color="black"
            class="white--text"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small class="mr-2" @click="getComprobacionesByListaId(item, false)">
              mdi-content-copy
            </v-icon>
          </template>
          <span class="white--text">Clonar</span>
        </v-tooltip>

        <v-tooltip
            v-if="permisoVista('comprobaciones', 'd')"
            bottom
            color="black"
            class="white--text"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="deleteItem(item)">
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Refrescar
        </v-btn>
      </template>
      <template v-slot:footer="{ props }">
        <v-col cols="12" class="d-flex justify-end align-center border-footer">
          <p class="mb-0 mr-8" style="font-size: 12px !important">
            Filas por página:
          </p>
          <v-text-field
              :value="props.pagination.itemsPerPage"
              dense
              hide-details
              disabled
              class="centered-input footer-input mt-0 mr-10"
          ></v-text-field>
          <p class="mb-0 mr-10" style="font-size: 12px !important">
            {{
              props.pagination.itemsLength ? getPageText(props.pagination) : "-"
            }}
          </p>

          <v-pagination
              v-model="listas_actual_page"
              :length="props.pagination.pageCount"
              :total-visible="10"
              :disabled="loading"
          ></v-pagination>
        </v-col>
      </template>
    </v-data-table>

    <v-snackbar v-model="alerta" top color="error">
      Existe una Lista de Comprobación con el mismo nombre
      <v-btn text @click="alerta = false"> Cerrar</v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import {mapState} from "vuex";
import DataTableRowHandler from "../../../DataTableRowHandler.vue";

export default {
  components: {
    draggable,
    DataTableRowHandler,
  },
  data() {
    return {
      search: "",
      itemforpage: [10],
      trabajoOrdenes_actual_page: 1,
      panels: [],
      unidades: [],
      unidadesonly: [],
      loadingUnidad: false,
      tiposListaComprobacion: [
        {
          text: "Liberación",
          value: 2,
        },
        {
          text: "Obligatoria",
          value: 1,
        },
        {
          text: "Opcional",
          value: 0,
        },
      ],
      errorTiempoRepeticion: "",
      errorTipoLista: "",
      loadingPreview: false,
      currentImage: "",
      dialogShowImage: false,
      keyImagen: 0,
      tiposComprobaciones: [
        {
          name: "Dicotómica (Si o No)",
          value: 1,
        },
        {
          name: "Valor Numérico",
          value: 2,
        },
      ],
      headersComprobaciones: [
        {
          text: "",
          align: "center",
          sortable: false,
          width: "5%",
          value: "handleIcon",
        },
        {
          text: "Comprobación",
          align: "center",
          sortable: false,
          width: "35%",
          value: "comprobacion",
        },
        {
          text: "Tipo de Comprobación",
          align: "center",
          sortable: false,
          width: "35%",
          value: "tipoComprobacion",
        },
        {
          text: "Imagen",
          align: "center",
          sortable: false,
          width: "20%",
          value: "imagen",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: "5%",
          value: "action",
        },
      ],
      sliderKey: 0,
      showTabla: false,
      savingOrdenar: false,
      modoOrdenar: false,
      areasOrden: [],
      drag: false,
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      headers: [
        {
          text: "Tipo de Lista",
          align: "center",
          sortable: true,
          value: "tipoLista",
        },
        {
          text: "Nombre de la lista",
          align: "center",
          sortable: true,
          value: "nombre",
        },
        {
          text: "Artículos a comprobar",
          align: "center",
          sortable: true,
          value: "articulos",
        },
        {
          text: "Áreas a comprobar",
          align: "center",
          sortable: true,
          value: "areas",
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false,
          width: "10%",
          align: "center",
        },
      ],
      loading: false,
      areas: [],
      articulos: [],
      delItem: "",
      action: 0,
      errores: [],
      editedIndex: -1,
      imagenGeneral: null,
      editedItem: {
        id: -1,
        nombre: "",
        abreviatura: "",
        codigo: "",
        comprobaciones: [],
        articulos: [],
        areas: [],
        tipoLista: null,
        tiempoRepeticion: null,
        imagenGeneral: this.imagenGeneral,
      },
      defaultItem: {
        id: -1,
        nombre: "",
        abreviatura: "",
        codigo: "",
        comprobaciones: [],
        articulos: [],
        areas: [],
        tipoLista: null,
        tiempoRepeticion: null,
        imagenGeneral: null,
      },
      itemComprobacion: {
        id: -1,
        comprobacion: "",
        tipoComprobacion: null,
        range: [3, 7],
        min: 1,
        max: 10,
        idUnidad: null,
      },
      step: 0.001,
      errorNombre: "",
      errorCodigo: "",
      errorAreas: "",
      errorArticulos: "",
      errorComprobaciones: [],
      errorDescripcion: [],
      errorTipo: [],
      errorMin: [],
      errorMax: [],
      filtros: {
        idArea: null,
        idArticulo: null,
      },
      //Paginacion de Listas (server side)
      listas_total: null,
      listas_items_per_page: 10,
      listas_actual_page: 1,
      listas: [],
    };
  },
  computed: {
    ...mapState(["token", "nombreEmpresa"]),
    formTitle() {
      return this.editedIndex === -1
          ? "Nueva Lista de Comprobación"
          : "Editar Lista de Comprobación";
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    // Search
    keyboardEventSearch($event) {
      if ($event instanceof KeyboardEvent) {
        //  Enter
        if ($event.keyCode === 13) this.listas_update_page_action(1);
      }
    },
    clearSearchAction() {
      this.search = "";
    },
    getPageText: ({pageStart, pageStop, itemsLength}) =>
        `${pageStart + 1}-${pageStop} de ${itemsLength}`,
    getUnidades() {
      this.loadingUnidad = true;
      axios
          .get("/Unidades")
          .then((response) => {
            this.unidades = response.data;
            this.loadingUnidad = false;
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    obtenerTipoLista(value) {
      var tipo = this.tiposListaComprobacion.filter((x) => x.value == value);
      if (tipo.length === 1) return tipo[0].text;
    },
    onChangeTipoLista() {
      this.editedItem.tiempoRepeticion = null;
      this.errorTiempoRepeticion = "";
    },
    deleteSeccion({idComprobacionGrupo}) {
      const indexSeccion = this.editedItem.comprobaciones.findIndex(
          (comp) => comp.idComprobacionGrupo == idComprobacionGrupo
      );
      this.editedItem.comprobaciones.splice(indexSeccion, 1);
    },
    listChanged(items) {
      this.$set(this.editedItem, "comprobaciones", items.slice());
    },
    closeShowImage() {
      this.dialogShowImage = false;
      this.currentImage = "";
    },
    showImage(item) {
      const url = `/Comprobaciones/ImagenComprobacion?imagen=${item.imagen}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
          .then(async (response) => {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            this.currentImage = await this.$utils.images.blobToBase64(blob);
            this.dialogShowImage = true;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    showImageGeneral(imagen) {
      const url = `/Comprobaciones/ImagenComprobacion?imagen=${imagen}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
          .then(async (response) => {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            this.currentImage = await this.$utils.images.blobToBase64(blob);
            this.dialogShowImage = true;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    clearImagenes(item) {
      item.imagenBlob = null;
      item.imagen = null;
      this.keyImagen++;
    },
    subirImagen($event, item) {
      if ($event == null) return;

      let formData = new FormData();
      formData.append("imagen", $event);
      item.loading = true;
      axios
          .post("/Comprobaciones/GuardarImagenComprobacionRespuesta", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            item.imagen = response.data;
            //console.log(item);
            item.loading = false;
          })
          .catch((error) => {
            item.imagenBlob = null;
            item.imagen = null;
            item.loading = false;
          })
          .finally(() => {
            this.keyImagen++;
          });
    },
    subirImagenGeneral($event) {
        if ($event == null) return; 

        let formData = new FormData();
        formData.append("imagen", $event); 
        axios.post("/Comprobaciones/GuardarImagenComprobacionRespuesta", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {    
            this.imagenGeneral = response.data; 
            //this.editedItem.imagenGeneral = this.imagenGeneral;
            //console.log(this.imagenGeneral);
            console.log("Soy la img general :P", this.imagenGeneral);
            //console.log("lala", this.editedItem.imagenGeneral);
        })
        .catch((error) => {
           console.log(error);
        })
        .finally(() => {
            this.keyImagen++;
        });
    },
    cambioRango(value, item, pos) {
      const numericValue = parseFloat(value);
      if (numericValue > item.min && numericValue < item.max) {
        item.range[pos] = numericValue;
        this.sliderKey++;
      }
    },
    cambioMin(value, item) {
      if (value != "" && !isNaN(value)) {
        if (parseFloat(item.min) >= parseFloat(item.max)) {
          item.range[0] = parseFloat(item.max) - this.step;
        }
        this.sliderKey++;
      } else {
        item.min = "0";
        item.range[0] = 0;
      }
    },
    cambioMax(value, item) {
      if (value != "" && !isNaN(value)) {
        if (parseFloat(item.max) <= parseFloat(item.min)) {
          item.range[1] = parseFloat(item.min) + this.step;
        }
        this.sliderKey++;
      } else {
        item.max = "1";
        item.range[1] = 1;
      }
    },
    nombreArticulo: (value) => {
      value.codigo != null
          ? `[${value.codigo}] - ${value.nombre}`
          : `${value.nombre}`;
    },
    borrarComp(seccion, item) {
      this.currentImage = "";
      const indexItem = seccion.preguntas.indexOf(item);
      seccion.preguntas.splice(indexItem, 1);
    },
    initialize() {
      this.search = "";
      this.getUnidades();
      this.getAreas();
      this.getArticulos();
      this.listas_update_page_action(this.listas_actual_page);
    },
    getArticulos() {
      axios
          .get("/Articulos/ArticulosNombres?componentes=true&activo=true")
          .then((response) => {
            this.articulos = response.data;
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    getAreas() {
      axios
          .get("/Areas")
          .then((response) => {
            this.areas = response.data.filter(
                (a) => a.idArea > 1 && a.condicion == true
            );
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    agregarSeccion() {
      const minnorId = Math.min.apply(
          Math,
          this.editedItem.comprobaciones.map((comp) => comp.idComprobacionGrupo)
      );
      const idComprobacionGrupo =
          minnorId === Infinity || minnorId - 1 >= 0 ? -1 : minnorId - 1;
      this.editedItem.comprobaciones.push({
        idComprobacionGrupo,
        nombreSeccion: `Sección ${this.editedItem.comprobaciones.length + 1}`,
        preguntas: [JSON.parse(JSON.stringify(this.itemComprobacion))],
      });
      this.errores = this.errores.filter(
          (error) => error != "Debe agregar al menos una comprobación."
      );
      this.panels.push(this.panels.length);
    },
    agregarItem(seccion) {
      seccion.preguntas.push(JSON.parse(JSON.stringify(this.itemComprobacion)));
      this.$set(seccion, "errorNombreSeccion", null);
    },
    showDialog() {
      this.action = 0;
      this.editedIndex = -1;
      this.agregarSeccion();
      this.dialog = true;
    },
    mapObjetoLista(item, comprobacionesParam = []) {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.id = item.idComprobacionLista;
      this.editedItem.nombre = item.nombre;
      this.editedItem.codigo = item.codigo;
      this.editedItem.imagenGeneral = item.imagen ? new File([""], item.imagen) : null;
      this.editedItem.areas = item.idsAreas;
      this.editedItem.articulos = item.idsArticulos;
      this.editedItem.tipoLista = item.tipoLista;
      this.editedItem.tiempoRepeticion = item.tiempoRepeticion;

      comprobacionesParam.forEach((seccion) => {
        this.editedItem.comprobaciones.push({
          ...seccion,
          preguntas: seccion.preguntas.map((pregunta) => ({
            id: pregunta.idComprobacion,
            comprobacion: pregunta.texto,
            tipoComprobacion: pregunta.tipoComprobacion,
            min: pregunta.tipoComprobacion === 2 ? pregunta.minimo + "" : "1",
            max: pregunta.tipoComprobacion === 2 ? pregunta.maximo + "" : "10",
            range:
                pregunta.tipoComprobacion === 2
                    ? [pregunta.minimoAceptado, pregunta.maximoAceptado]
                    : [3, 7],
            imagenBlob:
                pregunta.imagen != null ? new File([""], pregunta.imagen) : null,
            imagen: pregunta.imagen,
            idUnidad: pregunta.tipoComprobacion === 2 ? pregunta.idUnidad : null,
          })),
        });
      });

      this.editedItem.comprobaciones.forEach((_, index) =>
          this.panels.push(index)
      );
    },
    getComprobacionesByListaId(item, isEdit = true) {
      axios
          .get(
              `/Comprobaciones/ComprobacionesListar/${item["idComprobacionLista"]}`
          )
          .then((response) => {
            if (isEdit) {
              this.editItem(item, response.data);
              //console.log(item);
              this.imagenGeneral = item.imagen;
            } else {
              this.cloneItem(item, response.data);
            }
          })
          .catch((error) => {
            this.errorComprobaciones.push("Error al obtener las comprobaciones");
          });
    },
    editItem(item, comprobacionesParam) {
      this.action = 1;
      this.editedIndex = this.listas.indexOf(item);
      this.mapObjetoLista(item, comprobacionesParam);
      this.dialog = true;
    },
    cloneItem(item, comprobacionesParam) {
      this.action = 3;
      this.editedIndex = this.listas.indexOf(item);
      if (this.editedIndex > -1) {
        this.editedIndex = -1;

        this.mapObjetoLista(item, comprobacionesParam);

        this.dialog = true;
      }
    },
    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      axios
          .put(`/Comprobaciones/Desactivar/${this.delItem.idComprobacionLista}`)
          .then(() => {
            this.delItem = "";
            this.initialize();
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    close() {
      this.dialog = false;
      this.action = 0;
      this.editedIndex = -1;
      this.defaultItem.comprobaciones = [];
      this.editedItem = Object.assign({}, this.defaultItem);
      this.errores = [];
      this.errorNombre = "";
      this.errorCodigo = "";
      this.errorAreas = "";
      this.errorArticulos = "";
      this.currentImage = "";
      this.errorTipoLista = "";
      this.errorTiempoRepeticion = "";
      this.errorDescripcion = [];
      this.errorTipo = [];
      this.errorMin = [];
      this.errorMax = [];
      this.panels = [];
    },
    validate() {
      let hayErrores = false;
      this.errores = [];
      this.errorNombre = "";
      this.errorCodigo = "";
      this.errorAreas = "";
      this.errorArticulos = "";
      this.errorTipoLista = "";
      this.errorTiempoRepeticion = "";
      this.errorDescripcion = [];
      this.errorTipo = [];
      this.errorMin = [];
      this.errorMax = [];

      if (this.editedItem.nombre == "") {
        this.errorNombre =
            "Debe indicar un nombre para la lista de comprobación.";
      }

      if (this.editedItem.codigo == "" && this.nombreEmpresa != "IMPSA") {
        this.errorCodigo =
            "Debe indicar un código para la lista de comprobación";
      }

      if (this.editedItem.tipoLista == null) {
        this.errorTipoLista = "Debe de indicar un tipo de comprobación.";
      }

      if (
          this.listas.filter(
              (element) =>
                  element.codigo == this.editedItem.codigo &&
                  this.nombreEmpresa != "IMPSA"
          ).length >= 1 &&
          this.action == 3
      ) {
        this.errores.push("Ingresa un código distinto al existente");
      }

      if (
          this.listas.filter(
              (element) => element.nombre == this.editedItem.nombre
          ).length >= 1 &&
          this.action == 3
      ) {
        this.errores.push("Ingresa un nombre distinto al existente");
      }

      // if (this.editedItem.areas.length === 0) {
      //   this.errorAreas =
      //     "Debe seleccionar al menos un área a comprobar";
      // }
      //
      // if (this.editedItem.articulos.length === 0) {
      //   this.errorArticulos =
      //     "Debe seleccionar al menos un artículo a comprobar";
      // }

      if (this.editedItem.comprobaciones.length === 0) {
        this.errores.push("Debe agregar al menos una comprobación.");
      } else {
        this.editedItem.comprobaciones.forEach((seccion) => {
          this.$set(seccion, "errorNombreSeccion", null);

          if (seccion.nombreSeccion === "" || seccion.nombreSeccion == null) {
            this.$set(seccion, "errorNombreSeccion", "Requerido");
            hayErrores = true;
          } else if (seccion.nombreSeccion.length < 5) {
            this.$set(seccion, "errorNombreSeccion", "Mínimo 5 caracteres");
            hayErrores = true;
          }

          if (seccion.preguntas.length === 0) {
            this.$set(
                seccion,
                "errorNombreSeccion",
                "Agregue al menos una comprobación"
            );
            hayErrores = true;
          } else {
            seccion.preguntas.forEach((pregunta) => {
              if (pregunta.comprobacion === "") {
                this.$set(
                    pregunta,
                    "errorNombre",
                    "Debe indicar una descripción"
                );
                hayErrores = true;
              }
              if (pregunta.tipoComprobacion == null) {
                this.$set(
                    pregunta,
                    "errorTipo",
                    "Debe indicar el tipo de comprobación"
                );
                hayErrores = true;
              }

              if (pregunta.tipoComprobacion === 2) {
                if (pregunta.min == null || pregunta.min === "") {
                  this.$set(pregunta, "errorMin", true);
                  hayErrores = true;
                }
                if (pregunta.max == null || pregunta.max === "") {
                  this.$set(pregunta, "errorMax", true);
                  hayErrores = true;
                }
              }
            });
          }
        });
      }

      return (
          this.errores.length === 0 &&
          this.errorNombre === "" &&
          this.errorCodigo === "" &&
          this.errorTipoLista === "" &&
          this.errorTiempoRepeticion === "" &&
          !hayErrores
      );
    },
    save() {
      if (!this.validate()) return;

      this.action = 0;
      this.saving = true;
      if (this.editedIndex > -1) {
        //Actualizar
        const comprobaciones = this.editedItem.comprobaciones.reduce(
            (acc, seccion) => {
              acc.push({
                ...seccion,
                preguntas: seccion.preguntas.map((pregunta) => ({
                  idComprobacion: pregunta.id,
                  texto: pregunta.comprobacion,
                  tipoComprobacion: pregunta.tipoComprobacion,
                  minimo:
                      pregunta.tipoComprobacion == 2
                          ? parseFloat(pregunta.min)
                          : null,
                  maximo:
                      pregunta.tipoComprobacion == 2
                          ? parseFloat(pregunta.max)
                          : null,
                  minimoAceptado:
                      pregunta.tipoComprobacion == 2 ? pregunta.range[0] : null,
                  maximoAceptado:
                      pregunta.tipoComprobacion == 2 ? pregunta.range[1] : null,
                  idUnidad:
                      pregunta.tipoComprobacion == 2 ? pregunta.idUnidad : null,
                  imagen: pregunta.imagen,
                })),
              });
              return acc;
            },
            []
        );

        var obj = {
          idComprobacionLista: this.editedItem.id,
          nombre: this.editedItem.nombre,
          codigo: this.nombreEmpresa == "IMPSA" ? "" : this.editedItem.codigo,
          imagen: this.imagenGeneral,
          comprobaciones,
          tipoLista: this.editedItem.tipoLista,
          repeticion: Number(this.editedItem.tiempoRepeticion),
          idsAreas:
              this.$utils.isValid(this.editedItem.areas) &&
              this.editedItem.areas.length > 0
                  ? this.editedItem.areas
                  : [],
          idsArticulos:
              this.$utils.isValid(this.editedItem.articulos) &&
              this.editedItem.articulos.length > 0
                  ? this.editedItem.articulos
                  : [],
        };

        axios
            .put("/Comprobaciones/Actualizar", obj)
            .then(() => {
              this.saving = false;
              this.close();
              this.initialize();
            })
            .catch((error) => {
              this.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  this.alerta = true;
                }
              }
            });
      } else {
        //Crear
        const comprobaciones = this.editedItem.comprobaciones.reduce(
            (acc, seccion) => {
              acc.push({
                nombreSeccion: seccion.nombreSeccion,
                preguntas: seccion.preguntas.map((pregunta) => ({
                  // idComprobacion: pregunta.id,
                  texto: pregunta.comprobacion,
                  tipoComprobacion: pregunta.tipoComprobacion,
                  minimo:
                      pregunta.tipoComprobacion == 2
                          ? parseFloat(pregunta.min)
                          : null,
                  maximo:
                      pregunta.tipoComprobacion == 2
                          ? parseFloat(pregunta.max)
                          : null,
                  minimoAceptado:
                      pregunta.tipoComprobacion == 2 ? pregunta.range[0] : null,
                  maximoAceptado:
                      pregunta.tipoComprobacion == 2 ? pregunta.range[1] : null,
                  idUnidad:
                      pregunta.tipoComprobacion == 2 ? pregunta.idUnidad : null,
                  imagen: pregunta.imagen,
                })),
              });
              return acc;
            },
            []
        );

        var obj = {
          nombre: this.editedItem.nombre,
          codigo: this.nombreEmpresa == "IMPSA" ? "" : this.editedItem.codigo,
          imagen: this.imagenGeneral,
          comprobaciones,
          tipoLista: this.editedItem.tipoLista,
          repeticion: Number(this.editedItem.tiempoRepeticion),
          idsAreas:
              this.$utils.isValid(this.editedItem.areas) &&
              this.editedItem.areas.length > 0
                  ? this.editedItem.areas
                  : [],
          idsArticulos:
              this.$utils.isValid(this.editedItem.articulos) &&
              this.editedItem.articulos.length > 0
                  ? this.editedItem.articulos
                  : [],
        };

        axios
            .post("/Comprobaciones/Crear", obj)
            .then(() => {
              //console.log(obj)              
              this.saving = false;
              this.close();
              this.initialize();
            })
            .catch((error) => {
              this.saving = false;
              //console.log(obj);
              if (error.response) {
                if (error.response.status == 409) {
                  this.alerta = true;
                }
              }
            });
      }
    },
    getListasComprobacion() {
      return new Promise((resolve, reject) => {
        let url = `/Comprobaciones/Listar?paginar=true&size=${this.listas_items_per_page}&page=${this.listas_actual_page}&search=${this.search}`;

        if (
            this.$utils.isValid(this.filtros.idArea) &&
            this.filtros.idArea.length > 0
        ) {
          this.filtros.idArea.forEach((idA) => {
            url += `&IdsAreas=${idA}`;
          });
        }

        if (
            this.$utils.isValid(this.filtros.idArticulo) &&
            this.filtros.idArticulo.length > 0
        ) {
          this.filtros.idArticulo.forEach((idA) => {
            url += `&IdsArticulos=${idA}`;
          });
        }

        axios
            .get(url)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    async listas_update_page_action(page) {
      this.listas_actual_page = page;
      this.loading = true;
      try {
        let response = await this.getListasComprobacion();
        this.listas = response.data.result;
        this.listas_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        //console.log(exception);
      }
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center !important;
}

.footer-input {
  font-size: 12px !important;
  max-width: 40px !important;
}

.border-footer {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}

.ghost-element {
  opacity: 0.5;
  background: rgb(247, 247, 247);
  border: 1px solid #546e7a;
}

.custom-padding-expansion-content >>> .v-expansion-panel-content__wrap {
  padding: 8px !important;
}

.no-stripped-table >>> tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.row-section {
  font-size: 14px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.table-font >>> div {
  font-size: 0.875rem !important;
}

.item-movible {
  cursor: move;
}

.seccion-movible {
  cursor: move !important;
}

.desahibilitar-draggable {
  pointer-events: none;
}
</style>
