import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{key:_vm.eliminar,attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.eliminar),callback:function ($$v) {_vm.eliminar=$$v},expression:"eliminar"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("¿Está seguro que desea eliminar este turno?")]),_c(VCardText,[_vm._v("Esta acción no se puede revertir y será permanente.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.eliminar = false; _vm.delItem = '';}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.eliminar = false; _vm.deleteItemDB()}}},[_vm._v("Aceptar")])],1)],1)],1)],1)],( _vm.permisoVista('turnos', 'r') )?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.turnos,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VDialog,{key:_vm.dialog,attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function( ref ){
var on = ref.on;
return [( _vm.permisoVista('turnos', 'c') )?_c(VBtn,_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}}, on ),[_vm._v("Nuevo Turno")]):_vm._e()]}}],null,false,2642209363),model:{value:( _vm.dialog ),callback:function ($$v) { _vm.dialog =$$v},expression:" dialog "}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Nombre del Turno"},model:{value:( _vm.editedItem.nombre ),callback:function ($$v) {_vm.$set(_vm.editedItem, "nombre", $$v)},expression:" editedItem.nombre "}})],1),_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Código de Turno","maxlength":"8"},model:{value:( _vm.editedItem.codigo ),callback:function ($$v) {_vm.$set(_vm.editedItem, "codigo", $$v)},expression:" editedItem.codigo "}})],1),_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('input-time',{attrs:{"label":"Inicio","max":_vm.fin},model:{value:(_vm.editedItem.inicio),callback:function ($$v) {_vm.$set(_vm.editedItem, "inicio", $$v)},expression:"editedItem.inicio"}})],1),_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('input-time',{attrs:{"min":_vm.inicio,"label":"Fin"},model:{value:(_vm.editedItem.fin),callback:function ($$v) {_vm.$set(_vm.editedItem, "fin", $$v)},expression:"editedItem.fin"}})],1),_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VExpansionPanels,{attrs:{"accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v("Horarios Adicionales")]),_c(VExpansionPanelContent,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.horariosAdicionales,"items":_vm.editedItem.horarios,"items-per-page":5,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c(VTextField,{model:{value:(item.nombreHorario),callback:function ($$v) {_vm.$set(item, "nombreHorario", $$v)},expression:"item.nombreHorario"}})],1),_c('td',[_c('input-time',{attrs:{"max":item.fin},model:{value:(item.inicio),callback:function ($$v) {_vm.$set(item, "inicio", $$v)},expression:"item.inicio"}})],1),_c('td',[_c('input-time',{attrs:{"min":item.inicio},model:{value:(item.fin),callback:function ($$v) {_vm.$set(item, "fin", $$v)},expression:"item.fin"}})],1),_c('td',[_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.RemoverLiberacionFase(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])])],1)]),(item.errorActivo)?_c('tr',{staticStyle:{"font-size":"0.75em","padding-top":"0","margin-top":"0"}},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_c('small',{staticClass:"error-message",staticStyle:{"color":"red","font-size":"0.75em","padding":"0","margin":"0"}},[_vm._v(" "+_vm._s(item.errormessages)+" ")])])]):_vm._e()]}}],null,false,2353012884)}),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"my-2",attrs:{"small":"","color":"green","outlined":""},on:{"click":_vm.agregarLiberacionFase}},[_vm._v("Agregar Horario")])],1)],1)],1)],1)],1)],1),_c('ul',_vm._l((_vm.errores),function(ex){return _c('li',{key:ex,staticClass:"red--text"},[_vm._v(_vm._s(ex)+" ")])}),0)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false; _vm.errores = [];}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","width":2,"size":24,"color":"red"}})]},proxy:true}],null,false,3597384639)},[_vm._v(" Guardar ")])],1)],1)],1),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:( _vm.search ),callback:function ($$v) { _vm.search =$$v},expression:" search "}})],1)]},proxy:true},{key:"item.action",fn:function( ref ){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function( ref ){
var on = ref.on;
return [( _vm.permisoVista('turnos', 'u') )?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}}, on ),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function( ref ){
var on = ref.on;
return [( _vm.permisoVista('turnos', 'd') )?_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}}, on ),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])])]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Refrescar")])]},proxy:true}],null,false,1650846049)}):_vm._e(),_c(VSnackbar,{attrs:{"top":"","color":"error"},model:{value:( _vm.alerta ),callback:function ($$v) { _vm.alerta =$$v},expression:" alerta "}},[_vm._v(" Existe un Turno Activo con el mismo nombre "),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Cerrar ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }